import React from 'react';
import Avatar from 'components/avatar';

import MSU from 'components/svg/michigan-state';

export interface EducationItem {
  name: string;
  role?: string;
  description?: string;
  date?: string;
  imageSrc: string;
}

export default [
  {
    name: 'Michigan State',
    role: 'Major in Geographic Information Systems',
    date: `Graduated 2012`,
    avatar: () => (
      <div>
        <Avatar size={60}>
          <MSU />
        </Avatar>
      </div>
    ),
  },
  {
    name: 'Dale Carnegie Course',
    date: `Class of Winter 2018`,
    avatar: () => (
      <div>
        <Avatar src={'/dale.png'} alt={`dale carnegie class`} size={60} />
      </div>
    ),
  },
];
