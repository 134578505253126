import React, { useState, useEffect } from 'react';
import Typography from 'components/typography';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

// Components
import Card from 'components/card';

interface Props {
  project: any;
  viewMore?: boolean;
}

export default function ProjectCard({ project, viewMore }: Props) {
  const {
    promotions,
    name,
    isRenderedHidden,
    description,
    imageSrc,
    link,
    role,
    date,
    responsibilities,
    imageSize,
  } = project;

  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(!isRenderedHidden);

  useEffect(() => {
    if (viewMore && isRenderedHidden && !isVisible) {
      setTimeout(() => {
        setIsVisible(true);
      });
    }
  }, [viewMore, isVisible]);

  return (
    <div
      className={clsx(classes.root, {
        [classes.isHidden]: !viewMore && isRenderedHidden,
        [classes.isAppearing]: viewMore && isRenderedHidden && !isVisible,
        [classes.isVisible]: isVisible,
      })}
    >
      <div className={classes.title}>
        <Typography variant="h3">{name}</Typography>
        <Typography variant="subtitle2">
          <a href={'http://www.' + link} target="_blank" rel="noopener">
            {link}
          </a>
        </Typography>
      </div>
      <Card
        title={role}
        companyName={name}
        description={description}
        responsibilities={responsibilities}
        imageSrc={imageSrc}
        promotions={promotions}
        date={date}
        key={role}
        imageSize={imageSize}
      />
    </div>
  );
}

const useStyles = createUseStyles((theme: any) => {
  return {
    root: {
      marginBottom: theme.spacing.unit * 4,
      transition: `${theme.transition.normal} opacity`,
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.media.sm]: {
        flexDirection: 'column',
      },
    },
    isHidden: {
      display: 'none',
      opacity: 0,
    },
    isAppearing: {
      opacity: 0,
    },
    isVisible: {
      opacity: 1,
    },
  };
});
