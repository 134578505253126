import React, { useState } from 'react';
import ProjectCard from 'components/project-card';
import Typography from 'components/typography';
import { createUseStyles } from 'react-jss';
import PAST_PROJECTS, { Project } from './_experience';

export default function Experience() {
  const classes = useStyles();
  const [viewMore, setViewMore] = useState(false);

  function handleViewMore() {
    setViewMore(true);
  }

  return (
    <div className={classes.root}>
      <Typography variant="h2" mb="mb2">
        Work Experience:
      </Typography>
      {PAST_PROJECTS.map((project: Project, index: number) => {
        return (
          <ProjectCard project={project} viewMore={viewMore} key={index} />
        );
      })}
      {!viewMore && (
        <div className={classes.viewMoreButton} onClick={handleViewMore}>
          View More Work Experiences
        </div>
      )}
    </div>
  );
}

const useStyles = createUseStyles((theme: any) => {
  return {
    root: {
      maxWidth: theme.width.page,
    },
    title: {
      marginBottom: theme.spacing.unit * 2,
    },
    viewMoreButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: 14,
      cursor: 'pointer',
      transition: `${theme.transition.normal} color`,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  };
});
