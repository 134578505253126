import React from 'react';
import '../styles/globals.css';
import Helmet from 'react-helmet';

// Components
import SEO from 'components/seo';
import Layout from 'components/layout';
import PageContent from 'components/page-content';
import ScenicBackground from 'components/scenic-background';
import JulesCard from 'components/jules-card';
import Projects from 'components/projects';
import Education from 'components/education';

import Experience from 'components/experience';
// import BlogsSection from 'components/blogs-section'; // TODO(Jules): Future project!
import HatsWorn from 'components/hats-worn';

const IndexPage = () => (
  <>
    <Helmet>
      <link rel="icon" href={'/favicon.ico'} />
    </Helmet>
    <SEO />
    <Layout>
      <ScenicBackground />
      <PageContent>
        <JulesCard />
        <Experience />
        <Projects />
        <HatsWorn />
        <Education />
        {/* <BlogsSection /> */}
      </PageContent>
    </Layout>
  </>
);

export default IndexPage;
