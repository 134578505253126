// This svg came from https://github.com/simple-icons/simple-icons
import React from 'react';

interface Props {
  style?: any;
}

export default function Hat(props: Props) {
  return (
    <svg x="0px" y="0px" viewBox="0 0 95 118.75" width="35" height="35">
      <g>
        <path d="M82.507,60.802c1.196,0.126,2.179,0.23,2.804,0.89c0.282-0.775,0.631-2.456,0.855-3.542   c0.198-0.958,0.37-1.785,0.522-2.293c0.269-0.896,0.336-2.572,0.402-4.193c0.05-1.252,0.103-2.548,0.248-3.566   c0.184-1.288-0.124-2.871-0.421-4.402c-0.215-1.107-0.418-2.154-0.418-3.027c0-1.954-1.151-6.79-3.033-8.828   c-2.202-2.385-5.356-5.108-7.125-5.698c-0.922-0.307-2.004-0.833-3.15-1.389c-1.354-0.657-2.754-1.335-3.813-1.601l-4.933-1.233   l-0.358-1.075c-0.293-0.148-1.031-0.468-2.174-0.619c-0.256-0.034-0.512-0.05-0.757-0.05c-0.968,0-1.437,0.239-1.498,0.326   c-0.175,0.65-0.297,1.097-0.747,1.097H58.83c-0.733,0.03-5.407,0.227-6.385,0.367c-0.162,0.023-0.407,0.047-0.719,0.078   c-1.73,0.169-5.781,0.565-9.117,2.337c-3.591,1.908-6.25,4.451-8.367,8.002c-1.364,2.288-2.095,4.51-2.682,6.295   c-0.234,0.712-0.444,1.349-0.658,1.885c-0.9,2.25-1.492,3.967-1.919,5.568c-0.192,0.72-0.267,1.344-0.339,1.947   c-0.109,0.905-0.212,1.761-0.721,2.565c-0.022,0.035-0.044,0.065-0.066,0.1c0.458-0.179,0.973-0.34,1.523-0.425   c1.62-0.247,4.453-0.199,5.98-0.001c1.196,0.156,7.872,1.913,11.458,2.856l1.775,0.465c1.637,0.423,8.312,1.597,11.161,2.098   l1.187,0.209c1.594,0.284,10.586,2.746,12.484,3.269l0.093-0.084l6.913,1.382C81.201,60.664,81.895,60.737,82.507,60.802z" />
        <path d="M26.277,52.75l-17,11.333c-0.599,0.553-1.68,1.77-1.782,2.487c-0.055,0.383,0.013,0.713,0.146,1.005   c0.055-0.192,0.116-0.407,0.186-0.656c0.199-0.711,1.188-1.751,2.404-2.528c1.254-0.803,3.168-1.322,4.649-1.328   c1.311,0.018,2.965,0.197,3.86,0.375l1.011,0.17L20,63.695c0.328,0.117,8.069,2.864,10.414,4.204   c2.214,1.266,8.361,4.278,11.729,5.289c2.33,0.698,4.816,0.845,6.492,0.845c1.138,0,2.28-0.067,3.302-0.195   c2.563-0.321,6.672-2.786,9.973-4.767c3.52-2.112,7.617-6.139,8.403-6.925c0.854-0.854,1.848-1.779,2.455-2.337   c-2.542-0.696-10.516-2.87-11.96-3.128l-1.184-0.209c-3.101-0.545-9.553-1.68-11.219-2.109l-1.778-0.466   c-3.316-0.873-10.216-2.688-11.364-2.838c-1.476-0.193-4.256-0.235-5.77-0.001c-1.064,0.164-2.04,0.652-2.542,0.938   c-0.336,0.418-0.576,0.668-0.594,0.687L26.277,52.75z" />
        <path d="M14.87,63.813c-1.321,0.004-3.093,0.479-4.235,1.21c-1.259,0.805-1.978,1.712-2.086,2.1   c-0.145,0.518-0.264,0.93-0.342,1.201c0.263,0.232,0.563,0.42,0.851,0.563c1.067,0.532,2.469-0.21,4.709-1.405l0.165-0.089   c1.691-0.902,3.819-2.371,4.913-3.143C17.727,64.041,15.857,63.826,14.87,63.813z" />
      </g>
    </svg>
  );
}
