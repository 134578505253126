import React from 'react';
import { createUseStyles } from 'react-jss';
import Typography from 'components/typography';

interface Props {
  item: any;
}

export default function ProjectCard({ item }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>{item.avatar()}</div>
      <div className={classes.contentContainer}>
        <div className={classes.titleContainer}>
          <Typography variant="h4">{item.name}</Typography>
          {item.date && (
            <Typography variant="subtitle2">{item.date}</Typography>
          )}
        </div>
        {item.role && <Typography variant="body1">{item.role}</Typography>}
      </div>
    </div>
  );
}

const useStyles = createUseStyles((theme: any) => {
  return {
    root: {
      marginBottom: theme.spacing.unit * 4,
      transition: `${theme.transition.normal} opacity`,
      padding: theme.spacing.unit * 2,
      paddingLeft: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    imageContainer: {
      display: 'flex',
      flex: 0,
      width: 100,
      justifyContent: 'center',
      marginRight: theme.spacing.unit * 2,
      // if mobile -> marginRight: 0;
    },
    contentContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    titleContainer: {
      marginBottom: theme.spacing.unit,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.media.xs]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      // marginBottom: theme.spacing.unit,
    },
    isHidden: {
      display: 'none',
      opacity: 0,
    },
    isAppearing: {
      opacity: 0,
    },
    isVisible: {
      opacity: 1,
    },
  };
});
