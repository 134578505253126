import React from 'react';
import ListItem from './list-item';
import Typography from 'components/typography';
import { createUseStyles } from 'react-jss';
import DATA from './_data';

export default function Projects() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h2" mb="mb2">
        Education:
      </Typography>
      {DATA.map((data: any, index: number) => {
        return <ListItem item={data} key={index} />;
      })}
    </div>
  );
}

const useStyles = createUseStyles((theme: any) => {
  return {
    root: {
      maxWidth: theme.width.page,
    },
  };
});
