import React from 'react';
import Typography from 'components/typography';

export default [
  {
    category: 'Team Leader',
    description: (
      <Typography variant="body1" mb="mb4">
        I was the director for the engineering team at Sift and before that an
        engineering leader for all six frontend engineers. I'm a leader that
        balances executing a company's road map with engineering quality. I
        believe both have to coexist to create a great product and engineering
        culture.
        <br />
        <br />
        I'm a believer in the engineer's mind being the RIO of the engineering
        team. Engineering leaders have the responsibility of directing and
        protecting the engineer's context.
        <br />
        <br />
        Skilled in building engineering teams as well. I have experience
        recruiting engineers ranging from junior to staff in the dry markets of
        Detroit tech city.
      </Typography>
    ),
    items: [
      'Day to day operations, goal setting and performance management',
      'Mentorship for frontend Engineering Teams',
      'Building & Recruiting Teams',
      'Crafting & Executing Road Map OKRs',
      'Regular 1 on 1s and annual performance reviews',
      'Project Management',
    ],
  },
  {
    category: 'Frontend Engineer',
    description: (
      <Typography variant="body1" mb="mb4">
        Frontend engineering is my main craft. My applications have been used by
        thousands of people ranging from desktop, mobile and web platforms that
        I built with React. Experienced in mentoring engineers and challenging
        them to deepen their software skills. Building software that is
        scalable, risk averse and accomplishes the job to be done is important
        to me.
      </Typography>
    ),
    items: [
      'React, React Native & Electron',
      'Gatsby / NextJs',
      'Puppeteer / Cypress',
      'Jest',
      'Typescript',
      'Redux / React Context',
      'Webpack',
      'Babel',
      'CICD / Fastlane deployments',
    ],
  },
  {
    category: 'Product Engineer / Designer',
    description: (
      <Typography variant="body1" mb="mb4">
        I have built SAAS applications from researching other businesses and
        created an experience tailoring a solution from a problem.{' '}
        <a
          href="https://finishprobation.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          FinishProbation.com
        </a>{' '}
        is a product I built and designed from scratch. With over 3+ million
        Americans on probation, there isn’t software that helps the emotional
        side of probation. My aim here was to build a native app that helped
        manage probation better and gave a probation end date a victory to look
        forward to.
        <br />
        <br />
        When building this application, I've studied design systems to
        understand the right brand for this audience. I've also cold called
        lawyers and probation officers to further understand using software to
        notify people on probation. Product Engineering is a subject I have a
        desire to learn more of.
      </Typography>
    ),
    items: [
      'Product Empathy',
      'Product Design System',
      'Analytic Driven Decisions',
      'Technical Tradeoffs in Application Development',
      'User Interviews',
      'Cold Calling Interviews',
    ],
  },
  {
    category: 'Node.js Engineer',
    description: (
      <Typography variant="body1" mb="mb4">
        Experienced in maintaining and building upon existing node.js APIs.
      </Typography>
    ),
    items: [
      'Node / Express',
      'PostgreSQL / MongoDB',
      'GraphQL / Apollo',
      'REST Services',
    ],
  },
  {
    category: 'Marketing Engineer',
    description: (
      <Typography variant="body1" mb="mb4">
        I understand how to engineer websites so they can be discovered on
        search engines and social media. Marketing is a subject I have a desire
        to learn more of.
      </Typography>
    ),
    items: [
      'SEO',
      'Green Google Lighthouse scores',
      'Blogging',
      'Light weight websites',
    ],
  },
];
