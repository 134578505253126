import React from 'react';
import Typography from 'components/typography';
import { createUseStyles } from 'react-jss';
import Hat from './hat';
import hat_data from './hats';

export default function HatsWorm() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h2" mb="mb4">
        Hats I have Worn:
      </Typography>
      {/* <Typography variant="body1" mb="mb2">
        Hats I have Worn:
      </Typography> */}
      {hat_data.map((hat, index) => (
        <Hat hat={hat} key={index} />
      ))}
    </div>
  );
}

const useStyles = createUseStyles((theme: any) => {
  return {
    root: {
      maxWidth: theme.width.page,
    },
  };
});
