export interface Project extends ProjectItem {
  role: string;
  date: string;
}

export interface ProjectItem {
  name: string;
  description: string;
  imageSrc?: string;
  link?: string;
  promotions?: ProjectItem[];
}

export default [
  {
    role: 'Director of Engineering',
    date: 'Oct 2019 - Oct 2020',
    name: 'Sift',
    link: 'JustSift.com',
    description: `I was a founding member of Sift. 
      Since we began 2015, our goal was to connect people through enterprise data. 
      As a Director, I cared most about measuring the ROI of our engineering team.`,
    imageSrc: '/sift.jpg',
    responsibilities: [
      'I was responsible for the hiring and managing of the engineering team. Focusing on hitting delivery deadlines, scaling codebases and solving problems as a team.',
      `When I was the engineering director of a startup, I balanced the requirements of the road map with the quality of the code. I believe both need to exist to create a great product. Engineering leaders have the responsibility of directing and
      protecting the engineer's context.`,
      `Along with managing, I was the senior engineer and tech lead for all frontend applications. This involves more than 10 repositories ranging from React, React Native and Electron applications. 
      To maximize our throughput, we wrote safe and scalable code that is reusable across web, mobile and desktop applications.`,
    ],
    promotions: [
      {
        role: 'Engineering Manager',
        date: 'Aug 2018 - Oct 2019',
        name: 'Sift',
        link: 'JustSift.com',
        description: `
          In 2018, I became the Frontend Engineering Manager when the company grew past ten engineers. 
          My team was responsible for the iOS and Android apps built with React Native, 
          the Microsoft and Mac OS desktop app built with Electron, 
          the Marketing Website optimized for SEO and lastly our streamline enterprize web applications. 
          Our applications would get thousands of sessions daily.`,
        imageSrc: '/sift.jpg',
      },
      {
        role: 'Senior Software Engineer & Tech Lead',
        date: 'Dec 2017 - Aug 2018',
        name: 'Sift',
        link: 'JustSift.com',
        description: `
          In 2017, Sift sustained a NPS score of high 70s proving it to be worthy and desired product for businesses.
          With this, we started to hire more engineers and expand the team. 
          I became the tech lead for the frontend team, giving mentorship and while improving the codebase to better standards.`,
        imageSrc: '/sift.jpg',
      },
      {
        role: 'Software Engineer I & II',
        date: 'Sep 2015 - Dec 2017',
        name: 'Sift',
        link: 'JustSift.com',
        description: `
          Then in 2015, when the Rock Ventures startup "Tags" failed, we pivoted our social media application to find better market fit for enterprise companies. 
          We built a directory app encompassing all of Dan Gilbert's companies. 
          The application's ease of use and better search functionality inspired him to invest more in us.
          This application at the time was built with Angular x1 and over the course of 2017 was moved to a react codebase.`,
        imageSrc: '/sift.jpg',
      },
    ],
  },
  {
    role: 'Software Engineer',
    date: 'Sep 2014 - Sep 2015',
    name: 'Rock Ventures',
    link: 'RockVentures.com',
    description: `
      Joined a social media startup with Rock Ventures. Our goal was the connect employees by the tags they use to describe themselves.
      My role focused on the frontend.`,
    responsibilities: [],
    imageSrc: '/rock-ventures.png',
    isRenderedHidden: true,
  },
  {
    role: 'Software Developer',
    date: 'July 2013 - Sep 2014',
    name: 'Quicken Loans',
    link: 'QuickenLoans.com',
    responsibilities: [],
    description: `
      I was the only frontend engineer for a data integrity team called Möbius responsible for building the team's CRM with their data sets. 
      Möbius became the source of truth for all things client data in the Quicken Loans empire. 
      While working at QL, I gained experience working on big enterprize projects such as Billion Dollar Bracket.`,
    imageSrc: '/ql.jpg',
    isRenderedHidden: true,
  },
];
