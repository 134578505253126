import React from 'react';
import Typography from 'components/typography';
import Avatar from 'components/avatar';
import { createUseStyles } from 'react-jss';

interface Props {
  title: string;
  description: string;
  promotions: any;
  imageSrc?: string;
  date: string;
  companyName: string;
  responsibilities?: string[];
  imageSize?: number;
}

const PROMOTION_HEADER = 'h5';

export default function Card({
  promotions,
  title,
  companyName,
  description,
  imageSrc,
  date,
  responsibilities,
  imageSize,
}: Props) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <div className={classes.imageContainer}>
          <div>
            <Avatar
              src={imageSrc}
              alt={`${companyName} image`}
              size={60}
              imageSize={imageSize}
            />
          </div>
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.titleContainer}>
            <Typography variant="h4">{title}</Typography>
            {date && <Typography variant="subtitle2">{date}</Typography>}
          </div>
          <Typography variant="body1">{description}</Typography>
        </div>
      </div>
      <div className={classes.root}>
        <div className={classes.mockImageContainer}></div>
        <div className={classes.contentContainer}>
          {!!responsibilities && (
            <ul>
              {responsibilities.map(r => (
                <li key={r}>
                  <Typography variant="body2">{r}</Typography>
                </li>
              ))}
            </ul>
          )}
          {promotions && promotions.length > 0 && (
            <>
              <Typography variant="overline">
                Previously at {companyName}{' '}
              </Typography>
            </>
          )}
          {promotions &&
            promotions.map(p => {
              return (
                <div className={classes.promotion} key={p.role}>
                  <div className={classes.titleContainer}>
                    <Typography variant={PROMOTION_HEADER}>{p.role}</Typography>
                    {p.date && (
                      <Typography variant="subtitle2">{p.date}</Typography>
                    )}
                  </div>
                  <Typography variant="body2">{p.description}</Typography>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

const useStyles = createUseStyles((theme: any) => {
  return {
    root: {
      paddingTop: theme.spacing.unit,
      paddingRight: theme.spacing.unit * 2,
      paddingLeft: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      '& li': {
        marginBottom: theme.spacing.unit * 2,
      },
    },
    imageContainer: {
      display: 'flex',
      flex: 0,
      width: 100,
      justifyContent: 'center',
      marginRight: theme.spacing.unit * 2,
      // if mobile -> marginRight: 0;
    },
    mockImageContainer: {
      width: 60,
      [theme.media.xs]: {
        width: 0,
      },
    },
    contentContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    titleContainer: {
      marginBottom: theme.spacing.unit,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.media.sm]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    divider: {
      marginBottom: theme.spacing.unit * 0.75,
    },
    promotion: {
      marginBottom: theme.spacing.unit * 2,
      [`& > ${PROMOTION_HEADER}`]: {
        marginBottom: theme.spacing.unit,
      },
    },
  };
});
