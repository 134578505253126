export interface Project extends ProjectItem {
  role: string;
  date: string;
}

export interface ProjectItem {
  name: string;
  description: string;
  imageSrc?: string;
  link?: string;
}

export default [
  {
    name: 'FinishProbation',
    link: 'FinishProbation.com',
    description: `I built finishprobation.com in an effort to help the 3+ million Americans on probation. My aim here was to build a native app that helped manage probation by showing a probation end date as a finish line.`,
    imageSrc: '/finishprobation.png',
    imageSize: 60,
  },
  // {
  //   name: 'ScrapeHunter',
  //   description: `ScrapeHunter runs automated web scrapping scripts through puppeteer.
  //   A use case for ScrapeHunter would be executing a variety of search terms (like "Software Recruiting") along with every American city or state on Google.
  //   Resulting with a list of web links and descriptions from searches without the hassle of doing those Google searches.`,
  // },
  // {
  //   name: 'Grocery List Creator',
  //   description: `Looking to see if there's an opportunity to make money sending grocery list suggestions with affiliated links from Amazon, Walmart and Whole Foods from scraping their grocery receipts and pinned recipes from Pintrest at specific times of the week.`,
  // },
];
