import React from 'react';

interface Props {
  style?: any;
}

export default function MichiganState(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      viewBox="0 0 309.1 358.7"
      width="34"
      height="50"
      {...props}
    >
      {/* <path
        id="msu-s"

        d="M20.8 259.2v59.3L61 358.7h186.5l36-36.1V182.2l-45.1-45.1h-130V75.6h94.7v29h80.4V44L238.2-1.3H66.1L20.8 43.9v127.4l45.4 45.4h134.5v66.2h-95.1v-23.7z"
      /> */}
      <g id="msu-helmet">
        <path
          style={{ fill: '#004f39' }}
          d="M215.7 69.1c20 4.2 36.6 10.6 49.9 19.3l31.5-59.8c-10-7.3-23.5-13.6-40.4-19.1-34-11.2-68.6-12.4-103.8-3.9C82.8 22.5 38 57.3 18.1 109.8c-7.3 19.3-10.8 39.9-10.3 61.9.4 17.5 3 33 7.6 46.6 10.3 29.6 13.8 51.4 10.3 65.6-2.5 14.7-7.5 25.3-15.1 32-2.4 2.1-4.8 3.6-7.3 4.6l-3.3 1c24.8-2.8 40.4-17.9 46.6-45.3 2.4-10.5 3.1-26.5 2.1-47.8l-2.5-26c-.9-12.9-.5-24.5 1.5-34.8 5.7-30.7 24.5-56.5 56.5-77.4 34.2-22.3 71.3-29.3 111.5-21.1z"
        />
        <path
          style={{ fill: '#004f39' }}
          d="M190.4 77.6l-12.7.5-.9 42.3h3.4c3.4-13.9 12.1-24.1 25.7-30.8 6.9-3.4 13.3-5.4 19.4-5.8-12.3-3.8-23.9-5.7-34.9-6.2zM159.8 122.2l7.2-1.8-.5-41-14.3 2.6zM249.2 164.2c-19.6-21.5-46.2-30.5-79.7-27.1-22.1 2.2-40.8 13.8-56.1 34.7-5.7 7.9-10 16.1-12.8 24.7-2.7 7.9-3.4 13.9-2.2 18.2l1.8 16.3c1.5 11.5 3.3 18.7 5.4 21.8 2.8 4 3.6 14.3 1.9 31.1-1.6 17.3-4.8 28-9.6 31.7l82.4-15.4 29.3 23.3c23 16.9 43.2 28.6 60.7 35.1-11.1-35-14.9-72.8-11.8-113.5l-20.3-3c-14.2-3.6-22.4-9.4-25-17.5l3.7-3.6c3.1-2.5 6.7-4.6 10.5-6.1 11.7-4.6 23-2.7 33.9 5.8l12.6 47.7 13.3 2.4c9.9 2.4 17.2 5.4 22 9.1-17-20.2-30.5-47.7-40.5-82.8-3.2-11.3-9.6-22.3-19.5-32.9zM141 86.3l-12.4 4.2 14.6 37.7 8.4-3.9zM116.3 97.4l-9.4 5.5 21.7 31.5 6.4-4.3zM90.1 174.9l-29.4-5.4c-1.9 2.5-3.1 13.2-3.9 32-.7 22-.3 24.7 1.3 8.1 1.1-10.9 6.4-19 16.1-24.1 4.8-2.4 9.4-3.7 14.1-4.2l1.8-6.4zM67.6 147.7l-5.1 11.1 33.6 8.2 2.6-5.1zM81.2 125l-9 11.5 32 19.8 4.6-6zM115.3 144.3l5.2-6-22.1-29-10.3 9.3z"
        />
      </g>
    </svg>
  );
}
