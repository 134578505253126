import React from 'react';
import { createUseStyles } from 'react-jss';
import Avatar from 'components/avatar';
import Github from 'components/svg/Github';
import Spotify from 'components/svg/Spotify';
import LinkedIn from 'components/svg/LinkedIn';
import Instagram from 'components/svg/Instagram';
import email from 'consts/email';

const ICON_SIZE = 22;

function JulesCard() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Avatar
        size={200}
        alt="Jules Patry"
        src={'/jules-patry.jpeg'}
        className={classes.avatarClassName}
        imageClassName={classes.imageClassName}
      />
      <div id="nav-fade-in" />
      <h1 className={classes.h1}>Jules Patry</h1>
      <div className={classes.icons}>
        <a
          className={classes.icon}
          href="https://github.com/JulesPatry"
          target="_blank"
          rel="noopener"
        >
          <Github style={{ width: ICON_SIZE, height: ICON_SIZE }} />
        </a>
        <a
          className={classes.icon}
          href="https://www.linkedin.com/in/jules-patry-3216ba62/"
          target="_blank"
          rel="noopener"
        >
          <LinkedIn style={{ width: ICON_SIZE, height: ICON_SIZE }} />
        </a>
        <a
          className={classes.icon}
          href="https://www.instagram.com/jewels.party/?hl=en"
          target="_blank"
          rel="noopener"
        >
          <Instagram style={{ width: ICON_SIZE, height: ICON_SIZE }} />
        </a>
        <a
          className={classes.icon}
          href="https://open.spotify.com/artist/221qHKhutsbKs5keUXuqII"
          target="_blank"
          rel="noopener"
        >
          <Spotify style={{ width: ICON_SIZE, height: ICON_SIZE }} />
        </a>
      </div>
      <p>
        Was the Director of Engineering at{' '}
        <a href="https://www.justsift.com">Sift</a> with a primary focus on
        building front-end applications on web, mobile, and desktop platforms.
        <br />
        <br />
        Worn many hats in my career such as engineering leader, senior tech
        lead, product and marketing engineer.
        <br />
        <br />
        <a href={`https://emeraldsuite.club/`}>My side projects.</a>
        <br />
        <a href={`mailto:${email}`}>Let's talk.</a>
      </p>

      <br />
      <br />
      <br />
      <br />
      <br />

      {/* 
      <ul className={classes.list}>
        <li>
          I am entrepreneurial, creating a fully functional a SaaS company from
          the ground up.
        </li>
        <li>
          Built and scaled React, React Native and Electron Desktop applications
          used by thousands of people
        </li>
        <li>
          Hired and led a team of six engineers responsible for building all the
          frontend applications for Sift
        </li>
        <li>
          Given the chance, I will automate any tedious task like regression UI
          testing or web scraping leads.
        </li>
      </ul> */}
    </div>
  );
}

const useStyles = createUseStyles((theme: any) => {
  return {
    root: {
      marginTop: -110,
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      '& p': {
        maxWidth: theme.width.card,
      },
    },
    avatarClassName: {
      overflow: 'hidden',
      position: 'relative',
    },
    imageClassName: {
      left: -15,
      width: '250px !important',
      position: 'absolute',
      top: -20,
    },
    h1: {
      fontSize: 65,
      marginBottom: theme.spacing.unit * 6,
      marginTop: theme.spacing.unit * 4,
      [theme.media.xs]: {
        fontSize: 48,
      },
    },

    icons: {
      marginBottom: theme.spacing.unit * 2,
      display: 'flex',
      justifyContent: 'center',
      '& > a:not(:last-child)': {
        marginRight: theme.spacing.unit * 1.5,
      },
    },
    icon: {
      transition: `${theme.transition.normal} fill`,
      fill: theme.palette.common.black,
      '&:hover': {
        fill: theme.palette.primary.main,
      },
    },
    list: {
      textAlign: 'left',
      maxWidth: 400,
      '& > li': {
        marginBottom: theme.spacing.unit,
      },
    },
  };
});

export default JulesCard;
