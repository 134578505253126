import React from 'react';
import Typography from 'components/typography';
import { createUseStyles } from 'react-jss';
import HatIcon from 'components/svg/hat';

export default function HatsWorn({ hat }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.category}>
        <HatIcon />
        <Typography variant="h4">{hat.category}</Typography>
      </div>
      <div className={classes.content}>
        {!!hat.description && hat.description}
        <h4>Skills</h4>
        <ul>
          {(hat.items || []).map((h: string) => (
            <li key={h}>
              <Typography variant="body1">{h}</Typography>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

const useStyles = createUseStyles((theme: any) => {
  return {
    root: {
      marginBottom: theme.spacing.unit * 16,
      '& > p': {},
      '& ul': {
        display: 'flex',
        flexWrap: 'wrap',
      },
      '& li': {
        minWidth: 200,
        maxWidth: 300,
        width: '100%',
        marginBottom: theme.spacing.unit,
      },
    },
    category: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing.unit,

      '& > svg': {
        marginRight: theme.spacing.unit * 0.5,
      },
    },
    content: {
      paddingLeft: theme.spacing.unit * 5,
    },
  };
});
