import React, { useEffect } from 'react';
import ProjectCard from 'components/project-card';
import Typography from 'components/typography';
import { createUseStyles } from 'react-jss';
import CURRENT_PROJECTS from './_projects';

export default function Projects() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h2" mb="mb2">
        Current Projects:
      </Typography>
      {CURRENT_PROJECTS.map((project: any, index: number) => {
        return <ProjectCard project={project} key={index} />;
      })}
    </div>
  );
}

const useStyles = createUseStyles((theme: any) => {
  return {
    root: {
      maxWidth: theme.width.page,
    },
  };
});
